<div class="content" #content>
    <h1 class="title is-3">PHP Developer</h1>
    <h1 class="subtitle is-6">We are looking for motivated and experienced PHP Developers having sound
        knowledge in Laravel, Jquery, Java Script and Mysql Database.</h1>
    <h1 class="subtitle is-6"><strong>Roles & Responsibilities:</strong></h1>
    <h1 class="subtitle is-6">Analyze user needs to determine project technical requirements. Write clean
        code, programming and debugs software applications and solutions. Document developed modules, perform unit
        testing and participate in design and code-review process.
    </h1>
    <h1 class="subtitle is-6">Analyze user needs to determine project technical requirements. Write clean
        code, programming and debugs software applications and solutions. Document developed modules, perform unit
        testing and participate in design and code-review process.
    </h1>
    <h1 class="subtitle is-6">Responsible for physical database architecture and design, development,
        performance tuning, implementation, and support of the applications from a database perspective.</h1>
    <h1 class="subtitle is-6">Work closely with other application developers to test, configure, and
        optimize servers based on application demands; work with large volume, heavy load databases. Monitor and modify
        development work to make sure resulting software meets established goals.</h1>
    <a href="https://www.naukri.com/jobs-in-india?expJD=true" class="subtitle is-5 has-text-primary">Please click here to view the details</a>
</div>