import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-opening-content',
  templateUrl: './opening-content.component.html',
  styleUrls: ['./opening-content.component.scss']
})
export class OpeningContentComponent implements OnInit {

  @ViewChild('content', { static: true }) content: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }
  animate() {
    TweenMax.from(this.content.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      y: -20,
      ease: Expo.easeOut
    });
  }

}
