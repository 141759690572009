<header>
    <div class="container cons-vertical-flex">
        <figure class="image is-96x96" #logo>
            <img src="assets/logos/logo.png">
        </figure>
        <div style="margin-left: auto; display: flex;">
            <div class="cons-vertical-flex cons-header-element-container">
                <span class="material-icons has-text-primary">
                    location_on
                </span>
                <p class="help has-text-dark">
                    BJ 246, Sector II Salt Lake Kolkata 700091
                </p>
            </div>

             <div class="cons-vertical-flex cons-header-element-container cons-header-phone" (click)="openModal()">
                <span class="material-icons has-text-primary">
                    phone
                </span>
                <p class="help has-text-dark">
                    contact@consilioussystems.com
                    +91-33-7964-3628 / +91 9874712712
                </p>
            </div>
        </div>
    </div>
</header>

<div class="cons-nav has-background-dark">
    <div class="container cons-vertical-flex">
        <div class="cons-link" [ngClass]="{'is-active': currentRoute === '/'}" routerLink="/">
            <h1 class="subtitle is-5 has-text-white is-marginless">Home</h1>
            <div class="cons-active-link-indicator has-background-primary"></div>
        </div>
        <div class="cons-link" routerLink="/about-us" [ngClass]="{'is-active': currentRoute === '/about-us'}">
            <h1 class="subtitle is-5 has-text-white is-marginless">About Us</h1>
            <div class="cons-active-link-indicator has-background-primary"></div>
        </div>
        <div class="cons-link" routerLink="/ongoing-projects"
            [ngClass]="{'is-active': currentRoute === '/ongoing-projects'}">
            <h1 class="subtitle is-5 has-text-white is-marginless">Ongoing Projects</h1>
            <div class="cons-active-link-indicator has-background-primary"></div>
        </div>
        <div class="cons-link" routerLink="/contact-us" [ngClass]="{'is-active': currentRoute === '/contact-us'}">
            <h1 class="subtitle is-5 has-text-white is-marginless">Contact Us</h1>
            <div class="cons-active-link-indicator has-background-primary"></div>
        </div>
    </div>
</div>

<div class="modal" [ngClass]="{'is-active': isModalOpen}">
    <div class="modal-background" (click)="closeModal()"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        Contact Consilious
      </section>
    </div>
  </div>
