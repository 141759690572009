import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentBoxesComponent } from './components/content-boxes/content-boxes.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { OngoingProjectsComponent } from './pages/ongoing-projects/ongoing-projects.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProjectsContentComponent } from './components/projects-content/projects-content.component';
import { OfficeContentComponent } from './components/office-content/office-content.component';
import { OpeningContentComponent } from './components/opening-content/opening-content.component';
import { MobileViewComponent } from './modes/mobile-view/mobile-view.component';
import { FooterMobileComponent } from './components/footer-mobile/footer-mobile.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    ContentBoxesComponent,
    FooterComponent,
    AboutUsComponent,
    OngoingProjectsComponent,
    ContactUsComponent,
    ProjectsContentComponent,
    OfficeContentComponent,
    OpeningContentComponent,
    MobileViewComponent,
    FooterMobileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
