import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";
import { OfficeContentComponent } from 'src/app/components/office-content/office-content.component';
import { OpeningContentComponent } from 'src/app/components/opening-content/opening-content.component';
import { ProjectsContentComponent } from 'src/app/components/projects-content/projects-content.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  @ViewChild('heroImage', { static: true }) heroImage: ElementRef;
  @ViewChild('heroText', { static: true }) heroText: ElementRef;
  @ViewChild('navBox', { static: true }) navBox: ElementRef;

  currentContent: any = OfficeContentComponent;
  isOffice: boolean = true;
  isProjects: boolean = false;
  isOpening: boolean = false;

  constructor() { }


  ngOnInit(): void {
    this.animate();
    
  }

  animate(): void {
    TweenMax.from(this.heroImage.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      height: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.heroText.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.navBox.nativeElement, 2, {
      delay: 0.8,
      opacity: 0,
      ease: Expo.easeInOut,
    });
    
  }

  changeContent(contentName: string) {
    if(contentName === 'office') {
      this.currentContent = OfficeContentComponent;
      this.isOffice = true;
      this.isProjects = false;
      this.isOpening = false;
    }
    if(contentName === 'projects') {
      this.currentContent = ProjectsContentComponent;
      this.isOffice = false;
      this.isProjects = true;
      this.isOpening = false;
    }
    if(contentName === 'opening') {
      this.currentContent = OpeningContentComponent;
      this.isOffice = false;
      this.isProjects = false;
      this.isOpening = true;
    }
  }
}
