<div class="grid-container" #boxes>
  <div class="box">
    <h1 class="title is-4">IMS</h1>
    <h1 class="subtitle is-6">
      Unlock the power of your inventory.
    </h1>
  </div>
  <div class="box">
    <h1 class="title is-4">LMS</h1>
    <h1 class="subtitle is-6">
      World class training and certification management.
    </h1>
  </div>
  <div class="box">
    <h1 class="title is-4">iSurv</h1>
    <h1 class="subtitle is-6">
      Social survey application to capture dwelling and demographic information
      for city and road planning for a new India.
    </h1>
  </div>
  <div class="box">
    <h1 class="title is-4">iTraff</h1>
    <h1 class="subtitle is-6">
      Interactive vehicle, pedestrian, and other traffic object volumes counter
      to capture information for city and road planning in India; designed to
      surmount challenges from a lack of vehicle standardization.
    </h1>
  </div>
  <div class="box">
    <h1 class="title is-4">Smart Irrigation</h1>
    <h1 class="subtitle is-6">
      Data driven and automated soil irrigation solution.
    </h1>
  </div>
</div>
