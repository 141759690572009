import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-ongoing-projects',
  templateUrl: './ongoing-projects.component.html',
  styleUrls: ['./ongoing-projects.component.scss']
})
export class OngoingProjectsComponent implements OnInit {

  @ViewChild('ongoingProjectImg', { static: true }) ongoingProjectImg: ElementRef;
  @ViewChild('ongoingProjectTitle', { static: true }) ongoingProjectTitle: ElementRef;
  @ViewChild('ongoingDevelopment', { static: true }) ongoingDevelopment: ElementRef;
  @ViewChild('technology', { static: true }) technology: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }

  animate(): void {
    TweenMax.from(this.ongoingProjectImg.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      height: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.ongoingProjectTitle.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.ongoingDevelopment.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      x: -100,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.technology.nativeElement, 2, {
      delay: 0.7,
      opacity: 0,
      x: 100,
      ease: Expo.easeInOut,
    });

  }

}
