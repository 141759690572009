import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";
@Component({
  selector: 'app-office-content',
  templateUrl: './office-content.component.html',
  styleUrls: ['./office-content.component.scss']
})
export class OfficeContentComponent implements OnInit {

  @ViewChild('firstColumn', { static: true }) firstColumn: ElementRef;
  @ViewChild('secondColumn', { static: true }) secondColumn: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }

  animate() {
    TweenMax.from(this.firstColumn.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      x: -100,
      ease: Expo.easeOut,
    });
    TweenMax.from(this.secondColumn.nativeElement, 2, {
      delay: 0.2,
      opacity: 0,
      x: 100,
      ease: Expo.easeOut,
    });
  }

}
