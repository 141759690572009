import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('contactUsImg', { static: true }) contactUsImg: ElementRef;
  @ViewChild('contactUsTitle', { static: true }) contactUsTitle: ElementRef;
  @ViewChild('form', { static: true }) form: ElementRef;
  @ViewChild('detail', { static: true }) detail: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }

  animate(): void {
    TweenMax.from(this.contactUsImg.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      height: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.contactUsTitle.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.form.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      x: -100,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.detail.nativeElement, 2, {
      delay: 0.7,
      opacity: 0,
      x: 100,
      ease: Expo.easeInOut,
    });

  }

}
