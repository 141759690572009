import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-projects-content',
  templateUrl: './projects-content.component.html',
  styleUrls: ['./projects-content.component.scss']
})
export class ProjectsContentComponent implements OnInit {

  @ViewChild('boxes', { static: true }) boxes: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }
  animate() {
    TweenMax.from(this.boxes.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      y: 20,
      ease: Expo.easeOut
    });
  }

}
