<app-header></app-header>
<div class="hero">
    <figure class="image is-3by1" #contactUsImg>
        <img style="object-fit: cover;" src="assets/images/pexels-pixabay-237258.png">
    </figure>
    <h1 #contactUsTitle class="title is-1 has-text-white">Get In <strong class="has-text-primary">Touch</strong></h1>
</div>

<div class="container">
    <div class="columns" #form>
        <div class="column" style="border-right: 3px rgba(0, 0, 0, 0.25) solid;">
            <div class="field">
                <label class="label">Email</label>
                <div class="control">
                    <input class="input" type="email" placeholder="Tell us your email">
                </div>
            </div>
            <div class="field">
                <label class="label">Name</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Tell us your Name">
                </div>
            </div>
            <div class="field">
                <label class="label">Message</label>
                <div class="control">
                    <textarea class="textarea" placeholder="Write what you want to tell us"></textarea>
                </div>
            </div>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-primary">Submit</button>
                </div>
            </div>
        </div>
        <div class="column" #detail>
            <h1 class="title is-4">Contact us</h1>
            <h1 class="subttile is-6">BJ 246, Sector II Salt Lake Kolkata 700091</h1>
            <h1 class="subttile is-6">+91-33-7964-3628</h1>
            <h1 class="subttile is-6">+91 9874712712</h1>
            <h1 class="subttile is-6">contact@consilioussystems.com</h1>
        </div>
    </div>
</div>
<app-footer></app-footer>
