<div class="columns">
    <div class="column" style="border-right: 3px rgba(0, 0, 0, 0.25) solid;" #firstColumn>
        <h1 class="title is-3">Major Implementations</h1>
        <ul>
            <li class="subtitle is-5 has-text-dark">Traliant LLC</li>
            <li class="subtitle is-5 has-text-dark">Assorted Engineering Consultancy</li>
            <li class="subtitle is-5 has-text-dark">Abar Conseil</li>
            <li class="subtitle is-5 has-text-dark">Geo Profile</li>
            <li class="subtitle is-5 has-text-dark">Kornerstone</li>
            <li class="subtitle is-5 has-text-dark">A Farmers’ Consortium in Kenya</li>
        </ul>
    </div>
    <div class="column" #secondColumn>
        <h1 class="title is-3">Service Areas</h1>
        <ul>
            <li class="subtitle is-5 has-text-dark">AI: Machine Learning & Natural Language Processing</li>
            <li class="subtitle is-5 has-text-dark">IoT</li>
            <li class="subtitle is-5 has-text-dark">Mobile Applications</li>
            <li class="subtitle is-5 has-text-dark">Web Applications</li>
        </ul>
    </div>
</div>