<div class="cons-footer has-background-dark">
    <div class="columns">
        <div class="column">
            <h1 class="subtitle has-text-white is-4">Consilious Software Consulting LLP.</h1>
            <div class="margin"></div>
            <h1 class="subtitle has-text-white is-6">
                Founded in January 2018.
                Driven to help enable our customers’ business evolution.
                Motivated by excellence in collaboration, design and engineering.
            </h1>
        </div>
        <div class="column">
            <h1 class="subtitle has-text-white is-4">Useful links</h1>
            <div class="margin"></div>
            <ul>
                <li class="subtitle has-text-white is-6" routerLink="/" (click)="scrollToTop()">Home</li>
                <li class="subtitle has-text-white is-6" routerLink="/about-us" (click)="scrollToTop()">About Us</li>
                <li class="subtitle has-text-white is-6" routerLink="/ongoing-projects" (click)="scrollToTop()">Ongoing Projects</li>
                <li class="subtitle has-text-white is-6" routerLink="/contact-us" (click)="scrollToTop()">Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<div class="copyright-section">
    <h1 class="subtitle is-6 has-text-white">
        Copyrights © 2020 Consilious Software Consulting LLP. All Rights Reserved.
    </h1>
</div>
