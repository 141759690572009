<div class="is-hidden-mobile">
    <app-header></app-header>
    <div class="main-content">
        <div class="hero" #hero>
            <figure class="image is-3by1" #heroImage>
                <img style="object-fit: cover;" src="assets/images/pexels-negative-space-34092.png">
            </figure>
            <h1 #heroText class="title is-1 has-text-white">We think like the<br><strong
                    class="has-text-primary">Customers</strong>
                of
                our<br><strong class="has-text-primary">Customers</strong></h1>
        </div>
        <div class="nav-boxes" #navBox>
            <div class="cons-vertical-flex cons-is-centered">
                <div class="link-box has-background-white" [ngClass]="{'is-active': isOffice}"
                    (click)="changeContent('office')">
                    <span class="material-icons title" style="font-size: 5rem;">
                        precision_manufacturing
                    </span>
                    <h1 class="subtitle is-5">Work</h1>
                </div>
                <div class="link-box has-background-white" [ngClass]="{'is-active': isProjects}"
                    (click)="changeContent('projects')">
                    <span class="material-icons title" style="font-size: 5rem;">
                        dns
                    </span>
                    <h1 class="subtitle is-5">Projects</h1>
                </div>
                <div class="link-box has-background-white" [ngClass]="{'is-active': isOpening}"
                    (click)="changeContent('opening')">
                    <span class="material-icons title" style="font-size: 5rem;">
                        thumbs_up_down
                    </span>
                    <h1 class="subtitle is-5">Opening</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ng-container *ngComponentOutlet="currentContent"></ng-container>
    </div>
    <app-footer></app-footer>
</div>

<div class="is-hidden-desktop">
    <app-mobile-view></app-mobile-view>
</div>
