<app-header></app-header>
<div class="hero">
    <figure class="image is-3by1" #ongoingProjectImg>
        <img style="object-fit: cover;" src="assets/images/pexels-pixabay-268362.jpg">
    </figure>
    <h1 class="title is-1 has-text-white" #ongoingProjectTitle><strong class="has-text-primary">Machine Learning</strong> based platform of services to recognize images, videos, text for <strong class="has-text-primary">diverse applications</strong></h1>
</div>

<div class="container">
    <div class="columns" #ongoingDevelopment>
        <div class="column" style="border-right: 3px rgba(0, 0, 0, 0.25) solid;">
            <h1 class="title is-3">Ongoing Development</h1>
            <ul>
                <li class="subtitle is-5 has-text-dark">Asset tracking, automated employee attendance, automated material and package tracking.</li>
                <li class="subtitle is-5 has-text-dark">Vehicle classification and counting functionality for Indian road conditions for traffic volumes forecasting.</li>
                <li class="subtitle is-5 has-text-dark">IMS: Inventory Management System. Manage your inventory through your phone. Get business insights and make better decisions through AI driven reports.</li>
            </ul>
        </div>
        <div class="column" #technology>
            <h1 class="title is-3">Technology</h1>
            <ul>
                <li class="subtitle is-5 has-text-dark">Data modelling, Data pipeline, Data analytics for Machine Learning, and Analytics.</li>
                <li class="subtitle is-5 has-text-dark">Laravel, Express, NestJS, Vue, and React.</li>
                <li class="subtitle is-5 has-text-dark">Solutions on ARM, Nuvoton, and Microchip micro-controllers.</li>
                <li class="subtitle is-5 has-text-dark">Embedded systems, Geofencing solutions, Custom IOT solutions.</li>
            </ul>
        </div>
    </div>
</div>
<app-footer></app-footer>
