<header>
    <div class="material-icons" style="margin-right: auto;">menu </div>

    <figure class="image is-64x64">
        <img src="assets/logos/logo.png">
    </figure>

    <div class="material-icons" style="margin-left: auto;">alternate_email </div>
</header>
<div class="hero">
    <figure class="image is-5by3">
        <img style="object-fit: cover;" src="assets/images/pexels-negative-space-34092.png">
    </figure>
    <h1 class="title is-4 has-text-white">We think<br>like the
        <strong class="has-text-primary">Customers</strong>
        <br>
        of our <strong class="has-text-primary">Customers</strong></h1>
</div>

<div class="content">
    <h1 class="title is-4 has-text-dark">What we do?</h1>
    <h2 class="title is-6 has-text-dark">Let's see</h2>

    <h3 class="subtitle is-7 has-text-dark">
        Apply a vision of the future to designing software.
        Hold ourselves responsible towards our customers’ success.
        Push ourselves to experiment and celebrate our failures
        Build reusable and extensible software systems that are fun to use. Enjoy solving our customers’ problems.
    </h3>

    <button class="button is-warning is-small">See our works</button>

    <div class="image-with-text">
        <h1 class="title is-4 has-text-white">Major Implementations</h1>
    </div>

    <ul class="bold-lists">
        <li class="title is-6 has-text-dark">Traliant LLC</li>
        <li class="title is-6 has-text-dark">Assorted Engineering Consultancy</li>
        <li class="title is-6 has-text-dark">Abar Conseil</li>
        <li class="title is-6 has-text-dark">Geo Profile</li>
        <li class="title is-6 has-text-dark">Kornerstone</li>
        <li class="title is-6 has-text-dark">A Farmers’ Consortium in Kenya</li>
    </ul>

    <h1 class="title is-4 has-text-dark">Service Areas</h1>

    <div class="image-grid">
        <figure class="image is-square">
            <h1 class="title is-5 is-marginless has-text-white">Web <br> App</h1>
            <img src="assets/images/pexels-burst-374016.jpg" alt="" srcset="">
        </figure>
        <figure class="image is-square">
            <h1 class="title is-5 is-marginless has-text-white">AI</h1>
            <img src="assets/images/pexels-alex-knight-2599244.jpg" alt="" srcset="">
        </figure>
        <figure class="image is-square">
            <h1 class="title is-5 is-marginless has-text-white">IOT</h1>
            <img src="assets/images/pexels-panumas-nikhomkhai-1148820.jpg" alt="" srcset="">
        </figure>
        <figure class="image is-square">
            <h1 class="title is-5 is-marginless has-text-white">Mobile <br> App</h1>
            <img src="assets/images/pexels-peter-cors-360438.jpg" alt="" srcset="">
        </figure>
    </div>
</div>
<app-footer-mobile></app-footer-mobile>