<div class="cons-vertical-flex cons-is-centered">
    <div class="link-box has-background-white is-active">
        <span class="material-icons title" style="font-size: 5rem;">
            dns
        </span>
        <h1 class="subtitle is-4">Projects</h1>
    </div>
    <div class="link-box has-background-white">
        <span class="material-icons title" style="font-size: 5rem;">
            business
        </span>
        <h1 class="subtitle is-4">Office</h1>
    </div>
    <div class="link-box has-background-white">
        <span class="material-icons title" style="font-size: 5rem;">
            thumbs_up_down
        </span>
        <h1 class="subtitle is-4">Opening</h1>
    </div>
</div>