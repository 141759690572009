<div class="content">
    <div class="columns is-gapless is-marginless">
        <div class="column is-half">
            <h1 class="title is-5 has-text-dark">
                Consilious Software <br> Consulting LLP
            </h1>
            <h2 class="subtitle is-7">Email</h2>
            <h3 class="subtitle is-7 has-text-warning">contact@consilioussystems.com </h3>

            <h2 class="subtitle is-7">Phone</h2>
            <h3 class="subtitle is-7 has-text-warning">+91-33-7964-3628 <br> +91 9874712712</h3>

            <h2 class="subtitle is-7">Address</h2>
            <h3 class="subtitle is-7">BJ 246, Sector II Salt Lake Kolkata 700091</h3>

        </div>
    </div>
</div>
<section class="copyright">
    <h1 class="subtitle is-7">Copyrights © 2020 Consilious Software Consulting LLP. All Rights Reserved.</h1>
</section>
