<app-header></app-header>
<div class="hero">
    <figure class="image is-3by1" #aboutUsImg>
        <img style="object-fit: cover;" src="assets/images/pexels-pixabay-237258.png">
    </figure>
    <h1 #aboutUsTitle class="title is-1 has-text-white">About <strong class="has-text-primary">Us</strong></h1>
</div>

<div class="container">
    <div class="columns">
        <div class="column" style="border-right: 3px rgba(0, 0, 0, 0.25) solid;" #methodologies>
            <h1 class="title is-3">Methodologies</h1>
            <ul>
                <li class="subtitle is-5 has-text-dark">Strictly Agile</li>
                <li class="subtitle is-5 has-text-dark">Transparent Build, Test, Release, Monitor, Plan cycles.</li>
                <li class="subtitle is-5 has-text-dark">Fully JIRA / Bitbucket integrated workflow.</li>
                <li class="subtitle is-5 has-text-dark">1x Dev, 1x Code Review, 1.5x Test - is our time share.</li>
                <li class="subtitle is-5 has-text-dark">Conformation to SOLID, GRASP principles.</li>
                <li class="subtitle is-5 has-text-dark">Prevent pesticide paradox in testing.</li>
            </ul>
        </div>
        <div class="column" #whatWeDo>
            <h1 class="title is-3">What we do</h1>
            <ul>
                <li class="subtitle is-5 has-text-dark">Hold ourselves responsible towards our customers’ success.</li>
                <li class="subtitle is-5 has-text-dark">Push ourselves to experiment and celebrate our failures</li>
                <li class="subtitle is-5 has-text-dark">Apply a vision of the future to designing software.</li>
                <li class="subtitle is-5 has-text-dark">Build reusable and extensible software systems that are fun to use.</li>
                <li class="subtitle is-5 has-text-dark">Enjoy solving our customers’ problems.</li>
            </ul>
        </div>
    </div>
</div>
<app-footer></app-footer>
