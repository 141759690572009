import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { gsap } from "gsap";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  @ViewChild('aboutUsTitle', { static: true }) aboutUsTitle: ElementRef;
  @ViewChild('methodologies', { static: true }) methodologies: ElementRef;
  @ViewChild('whatWeDo', { static: true }) whatWeDo: ElementRef;
  @ViewChild('aboutUsImg', { static: true }) aboutUsImg: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.animate();
  }


  animate(): void {
    TweenMax.from(this.aboutUsImg.nativeElement, 2, {
      delay: 0,
      opacity: 0,
      height: 0,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.aboutUsTitle.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.methodologies.nativeElement, 2, {
      delay: 0.5,
      opacity: 0,
      x: -100,
      ease: Expo.easeInOut,
    });
    TweenMax.from(this.whatWeDo.nativeElement, 2, {
      delay: 0.7,
      opacity: 0,
      x: 100,
      ease: Expo.easeInOut,
    });

  }

}
